import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import { format, parseISO } from 'date-fns'
import { useFormik } from 'formik'
import { SalesDetailModal } from 'modals'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import ButtonLoadMore from 'components/button-load-more/button-load-more'
import Button from 'components/button/Button'
import { DefaultButton } from 'components/default-button/default-button'
import FlexTable from 'components/flex-table'
import { Grid } from 'components/grid/grid'
import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'
import { Text } from 'components/text/text'

import { useUI } from 'contexts'
import { formatCurrency, getFormattedDateFilters } from 'helpers'
import { useAuth } from 'hooks'
import api, { api2 } from 'services/api'
import { PagedList } from 'types'
import { Sales, SalesHeader } from 'types/sales'

import {
    ActionButton,
    ActionsButtonRow,
    Container,
    Content,
    DateFilterActionsContainer,
    FilterContainer,
    Header,
    HeaderTitle,
    PeriodButton,
    Row,
    SalesPanelContainer,
    SalesPanelContent,
    SalesPanelItem,
    SalesPanelItemTitle,
    SalesPanelItemValue,
    SalesPanelTitle,
    SearchIcon,
    StatusText,
} from './sales.styled'

const columnStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

type ITypesOperation = 'delivery' | 'indoor' | 'takeout'
type IStatus = 0 | 1

type IFilters = {
    start_date?: string
    end_date?: string
    selectedPeriod?: string
    type?: ITypesOperation | 'all'
    status: IStatus | 'all' | null
}

type IPaginationFilters = {
    has_more: boolean
    per_page: number
    current_page: number
}

const paginationValues = {
    has_more: false,
    per_page: 40,
    current_page: 1,
}

const SalesScreen = memo(_ => {
    const { store } = useAuth()
    const { setErrorModal, setLoading } = useUI()

    const salesDetailModalRef = useRef<SalesDetailModal>()

    const [paginationFilters, setPaginationFilters] = useState<IPaginationFilters>(paginationValues)
    const [salesHeader, setSalesHeader] = useState<SalesHeader>()
    const [sales, setSales] = useState<Sales[]>([])

    const { values, getFieldProps, handleSubmit, setValues } = useFormik<IFilters>({
        initialValues: {
            type: 'all',
            status: 1,
            selectedPeriod: 'today',
            start_date: format(new Date(), 'YYYY-MM-DD'),
            end_date: format(new Date(), 'YYYY-MM-DD'),
        },
        validationSchema: Yup.object().shape({}),
        onSubmit: async values => {
            setPaginationFilters(paginationValues)
            await _getSales(values, paginationValues)
        },
    })

    const periodFilters = getFormattedDateFilters<typeof values>(values, setValues)

    const paginationParams = useMemo((): IPaginationFilters => {
        return {
            current_page: paginationFilters.current_page,
            per_page: paginationFilters.per_page,
        } as IPaginationFilters
    }, [paginationFilters.current_page, paginationFilters.per_page])

    const hasPagination = useMemo((): boolean => {
        return Boolean(paginationFilters.has_more)
    }, [paginationFilters.has_more])

    const _showOrderDetails = useCallback(
        (sale: Sales) => () => {
            salesDetailModalRef.current.show(sale)
        },
        []
    )

    const _getOperationText = useCallback((type: ITypesOperation) => {
        return {
            delivery: 'Delivery',
            takeout: 'Balcão',
            indoor: 'Atendimento em Mesa',
        }[type]
    }, [])

    const _getSales = useCallback(
        async (filters: IFilters, paginationFilters?: IPaginationFilters) => {
            const { selectedPeriod, ...rest } = filters

            if (rest.status === 'all') {
                delete rest.status
            }

            setLoading(true)
            try {
                const { data } = await api.get<PagedList<Sales, SalesHeader>>('/painel/report/sales', {
                    params: {
                        stores: [store.id],
                        ...paginationFilters,
                        ...(rest ? rest : null),
                        type: rest.type !== 'all' ? rest.type : null,
                    },
                })
                setPaginationFilters(state => ({
                    ...state,
                    has_more: data.has_more,
                    current_page: data.current_page,
                }))
                setSalesHeader(data.header)
                setSales(state => {
                    if (data.current_page > 1) {
                        return [...state, ...data.items]
                    }
                    return data.items
                })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Houve um erro a listar relatório de vendas',
                })
            } finally {
                setLoading(false)
            }
        },
        [store]
    )

    const _refreshPagination = useCallback(async () => {
        await _getSales(values, { ...paginationParams, current_page: paginationParams.current_page + 1 })
    }, [_getSales, values, paginationParams])

    const _getStatusName = useCallback((value?: boolean) => {
        if (value === null) {
            return 'Pendente'
        }
        return value ? 'Aceito' : 'Recusado'
    }, [])

    const _downloadSalesReport = useCallback(async () => {
        const { start_date, end_date } = values

        const { data } = await api2.get(`/report/products`, {
            responseType: 'blob',
            params: {
                store_id: store.id,
                start_date,
                end_date,
                download: 'xlsx',
                prepaid: 1,
            },
        })

        const file = new Blob([data], { type: data.type })
        const downloadUrl = window.URL.createObjectURL(file)
        let periodFileName = ''

        if (start_date === end_date) {
            periodFileName = format(start_date, 'DD/MM/YYYY')
        } else {
            periodFileName = `${format(start_date, 'DD/MM/YYYY')} à ${format(end_date, 'DD/MM/YYYY')}`
        }

        const filename = `${store.name} - Vendas (pré-pago) de ${periodFileName}.xlsx`
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
        setTimeout(() => window.URL.revokeObjectURL(downloadUrl), 100)
    }, [values, store])

    const _formatTransaction = useCallback((transaction: Sales['transaction']) => {
        if (transaction) {
            const { gateway, id } = transaction
            const prefix = {
                sitef: 'NSU',
                zoop: 'ID',
            }

            return `${prefix[gateway]}: ${id}`
        }

        return ''
    }, [])

    useEffect(() => {
        _getSales(values, paginationParams)
    }, [_getSales])

    useEffect(() => {
        const query = new URLSearchParams(location.search)

        setValues({
            start_date: query.get('start_date') ?? format(new Date(), 'YYYY-MM-DD'),
            end_date: query.get('end_date') ?? format(new Date(), 'YYYY-MM-DD'),
            status: query.get('status') ? (Number(query.get('status')) as IStatus) : 1,
            type: (query.get('type') as ITypesOperation) ?? null,
        })
    }, [location.search])

    return (
        <Container>
            <SalesDetailModal ref={salesDetailModalRef} />
            <Header>
                <Row>
                    <HeaderTitle>Vendas (Pré pago)</HeaderTitle>
                </Row>
                <FilterContainer>
                    <InputItem labelText="Data inicial" type="date" inputProps={getFieldProps('start_date')} />
                    <InputItem labelText="Data Final" type="date" inputProps={getFieldProps('end_date')} />
                    <InputItem
                        labelText="Tipo de Operação"
                        type="select"
                        options={[
                            { label: 'Todos', value: 'all' },
                            { label: 'Delivery', value: 'delivery' },
                            { label: 'Balcão', value: 'takeout' },
                            { label: 'Atendimento em Mesa', value: 'indoor' },
                        ]}
                        inputProps={getFieldProps('type')}
                    />
                    <InputItem
                        labelText="Status"
                        type="select"
                        options={[
                            { label: 'Todos', value: 'all' },
                            { label: 'Aceito', value: 1 },
                            { label: 'Pendente', value: null },
                            { label: 'Recusado', value: 0 },
                        ]}
                        inputProps={getFieldProps('status')}
                    />
                    <DefaultButton widthFitContainer onClick={() => handleSubmit()}>
                        <SearchIcon />
                    </DefaultButton>
                </FilterContainer>
                <DateFilterActionsContainer>
                    {periodFilters.map((filter, filterIndex) => (
                        <PeriodButton
                            key={filterIndex}
                            outline
                            widthFitContainer
                            isSelected={filter.key === values.selectedPeriod}
                            onClick={filter.onClick}
                        >
                            {filter.label}
                        </PeriodButton>
                    ))}
                </DateFilterActionsContainer>
            </Header>
            <SalesPanelContainer>
                <SalesPanelTitle>Valores Gerais</SalesPanelTitle>
                <SalesPanelContent>
                    <SalesPanelItem>
                        <SalesPanelItemTitle>Qtd de Pedidos</SalesPanelItemTitle>
                        <SalesPanelItemValue>{salesHeader?.orders_total ?? 0}</SalesPanelItemValue>
                    </SalesPanelItem>
                    <SalesPanelItem>
                        <SalesPanelItemTitle>Comissão</SalesPanelItemTitle>
                        <SalesPanelItemValue>{store.configs?.commission ?? 0}%</SalesPanelItemValue>
                    </SalesPanelItem>
                    <SalesPanelItem>
                        <SalesPanelItemTitle>Valor Total Bruto</SalesPanelItemTitle>
                        <SalesPanelItemValue>{formatCurrency(salesHeader?.total_amount)}</SalesPanelItemValue>
                    </SalesPanelItem>
                    <SalesPanelItem>
                        <DefaultButton
                            simple
                            widthFitContainer
                            outline
                            title="Baixar relatório de vendas"
                            onClick={_downloadSalesReport}
                        />
                    </SalesPanelItem>
                    {/*
                        <SalesPanelItem>
                            <SalesPanelItemTitle>Valor a Receber</SalesPanelItemTitle>
                            <SalesPanelItemValue variant="success">
                                {formatCurrency(salesHeader?.total_to_receive)}
                            </SalesPanelItemValue>
                        </SalesPanelItem>
                    */}
                </SalesPanelContent>
            </SalesPanelContainer>
            <Content>
                <FlexTable
                    columns={[
                        { name: 'Status', width: '5%', style: columnStyle },
                        { name: 'ID Pedido', width: '10%', style: columnStyle },
                        { name: 'Cliente', width: '20%', style: columnStyle },
                        { name: 'Data', width: '10%', style: columnStyle },
                        { name: 'Método de Pagamento', width: '15%', style: columnStyle },
                        { name: 'Tipo de Operação', width: '15%', style: columnStyle },
                        { name: 'Valor da Venda', width: '15%', style: columnStyle },
                        //{ name: 'R$ a Receber', width: '15%', style: columnStyle },
                        { name: '', width: '10%', style: columnStyle },
                    ]}
                    list={sales?.map(item => ({
                        status: <StatusText isActive={item.status}>{_getStatusName(item.status)}</StatusText>,
                        id: item.id.toString(),
                        client: item.customer_name,
                        data: format(item.close_date, 'DD/MM/YYYY [às] HH:mm'),
                        payment_method: (
                            <Grid.Column align="center">
                                <Text text={item?.transaction?.type} align="center" dimensions="xSmall" />
                                <Text
                                    text={_formatTransaction(item.transaction)}
                                    dimensions="caption"
                                    marginBottom={2}
                                    marginTop={2}
                                />
                                <Text text={item.transaction?.gateway} dimensions="caption" uppercase />
                            </Grid.Column>
                        ),
                        type: _getOperationText(item.type as ITypesOperation),
                        saleValue: formatCurrency(item.total_price),
                        //toReceive: formatCurrency(item.total_to_receive),
                        actions: (
                            <ActionsButtonRow>
                                <ActionButton onClick={_showOrderDetails(item)}>Ver Mais</ActionButton>
                            </ActionsButtonRow>
                        ),
                    }))}
                />
            </Content>
            <ButtonLoadMore loading={false} visible={hasPagination} onClick={_refreshPagination} />
        </Container>
    )
})

export { SalesScreen }
